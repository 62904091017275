export default {
  GET(state, data) {
    state.customer = { ...data }
  },
  LIST(state, data) {
    state.customers = data
  },
  SET_CUSTOMER_STATUS(state, data) {
    state.customerStatus = data
  },
  SET_CSV_TEMPLATE(state, data) {
    state.CSVTemplate = data
  },
  SET_ACCOUNT_PARAMETERS_FORM(state, data) {
    state.accountParametersForm = { ...data }
  },
  // eslint-disable-next-line no-unused-vars
  RESET_CUSTOMER(state, _) {
    // state.customers.categories = []
    state.customers.tags = []
  },
  SET_PRIMARY_CONTACT_INFORMATION_FORM(state, data) {
    state.primaryContactInformationForm = { ...data }
  },
  SET_PRIMARY_ADDRESS_INFORMATION_FORM(state, data) {
    state.primaryAddressInformationForm = { ...data }
  },
  SET_MORE_CONTACT_INFORMATION_FORM(state, data) {
    state.moreContactInformationForm = { ...data }
  },
  SET_EXPORT(state, data) {
    state.export = { ...data }
  },
  SET_IMPORT(state, data) {
    state.import = { ...data }
  },
}
