import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import store from '@/store'
import { customActionGet } from '@core/utils/utils'
import { stringify } from 'qs'

const endpoint = 'customers'
const suppliersEndpoint = () => 'customers'
const Actions = crudActions(endpoint)
const deleteAttachment = (ctx, data) => axios.delete(`/attachments/${data.id}`)
const importCSV = (ctx, { formData, import_type }) => axios.post(`${endpoint}/csv-import`, formData, { params: { import_type } })
const getDefaults = () => axios.get('/settings/customers/default')

const getVerification = () => axios.get(`/customers/${store.state.customers?.customer?.id}/verification`)

// const updateCustomer = (ctx, data) => {
//   const { customerFormData, customerId } = data
//   return axios.post(`/${endpoint}/${customerId}?_method=PUT`, customerFormData)
// }
// const createCustomer = (ctx, data) => {
//   const { contactsFormData } = data
//   return axios.post(`/${endpoint}`, contactsFormData)
// }

const updateCustomer = (ctx, data) => {
  console.log(data);
  return axios.post('/customers/complete-registration/update', data)
}
const uploadAttachment = (ctx, data) => axios.get(`/attachments/${data.id}`)
const getCustomer = ({ }, { id, queryParams = {} }) => customActionGet(`customers/${id}`, queryParams)
const suppliers = (_, queryParams) => customActionGet(suppliersEndpoint(), queryParams, () => {}, '')

const getCustomerInAccountPage = ({ commit }, queryParams) => new Promise((resolve, reject) => {
  if (!queryParams.hasOwnProperty('f')) {
    queryParams = { ...queryParams, ...{ f: { } } }
  }
  axios.get(`${endpoint}`, { params: queryParams, paramsSerializer: params => stringify(params) })
    .then(response => {
      const { data } = response.data.data
      data.forEach(item => {
        // eslint-disable-next-line no-param-reassign
        item.action = {
          key: 'startAQuote',
          label: 'Start a Quote',
        }
      })
      commit('LIST', data)
      resolve(response)
    })
    .catch(error => {
      reject(error)
    })
})

export default {
  ...Actions,
  deleteAttachment,
  updateCustomer,
  // createCustomer,
  uploadAttachment,
  importCSV,
  getDefaults,
  getVerification,
  getCustomer,
  getCustomerInAccountPage,
  suppliers,
}
